import React, {useEffect, useState, useRef} from 'react';
import {get} from 'lodash';
import propTypes from 'prop-types';
import styled from 'styled-components';
import renderHTML from 'react-render-html';
import { Select, MenuItem, InputLabel, FormControl, ButtonBase } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {theme} from '../../../themes/default-theme';
import {ReactSVG} from 'react-svg';
import chevronDown from '../../../assets/icons/chevron-down.svg';
import mobileCheck from '../../../util/mobileCheck';
import Router from 'next/router';

export const dropdownTheme = createTheme({
  ...theme,
})

const liteTheme = createTheme({
  ...theme,
})

const v2 = createTheme({
  ...theme,
})

dropdownTheme.overrides = {
  ...theme.overrides,
  Complete: {
    'fieldset': {
      background: 'red'
    }
  },
  MuiInputLabel: {
    root: {

      "&$outlined": {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        paddingLeft: 19,
        fontSize: 14,
        '&$shrink': {
          transform: 'translateY(-50%)'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 16
        }
      }
    },
  },
  MuiFormLabel: {
    root: {
      "&$focused": {
        color: theme.palette.darkGrey,
      },
      '&$filled': {
        display: 'none'
      }
    }
  },
  MuiList: {
    root: {
      "&$padding": {
        padding: 0,
      }
    } 
  },
  MuiPaper: {
    root: {
      "&$elevation8": {
        boxShadow: 'none',
        marginTop: 20,
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.middleGrey}`,
      }
    }
  },
  MuiSelect: {
    root: {
      minHeight: '20px',
      lineHeight: '20px',
      "&$select:focus": {
        backgroundColor: 'transparent'
      }
    },
    // select: {
    //   paddingRight: 40,
    // },
    disabled: {
      opacity: 0.5
    }
  },
  MuiListItem: {
    root: {
      fontSize: '16px',
      "&$selected, &$selected:hover": {
        backgroundColor: theme.palette.vividBlue,
        color: 'white'
      }
    }
  },
  MuiMenuItem: {
    root: {
      fontSize: '16px',
      padding: '12.5px 19px',
      paddingTop: '12.5px',
      paddingBottom: '12.5px',
      "&:not(:last-child)": {
        borderBottom: `1px solid ${theme.palette.middleGrey}`,
      },
    }
  }
}

liteTheme.overrides = {
  ...dropdownTheme.overrides,
  MuiOutlinedInput: {
    root: {
      backgroundColor: 'transparent',
      border: 0,
      color: theme.palette.white,
    },
    notchedOutline: {
      border: 0
    },
    input: {
      padding: '5px 14px'
    }
  },
  MuiFormLabel: {
    root: {
      color: theme.palette.white,
    },
    filled: {
      display: 'none !important',
      opacity: 0
    }
  }
}

liteTheme.overrides = {
  ...dropdownTheme.overrides,
  MuiListItem: {
    root: {
      fontSize: '16px',
      "&$selected, &$selected:hover": {
        backgroundColor: theme.palette.vividBlue,
        color: 'white'
      }
    }
  }
}

const ChevronIcon = styled(ButtonBase)`
  pointer-events: none;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 7px;
  right: 10px;
  border-radius: 24px;
  transition: transform 300ms ease-in-out;

  ${props => props.theme === 'lite' ? `
    top: 4px;
  ` : `
    background-color: ${theme.palette.vividBlue};
  `}

  ${props => props.theme === 'v2' && `
    background-color: white;

    svg g {
      stroke: #758094;
    }
  `}

  ${theme.breakpoints.up('md')} {
    top: 10px;

    ${props => props.theme === 'lite' && `
      top: 5px;
    `}
  }

  ${props => props.active === 1 && `
    transform: rotate(180deg);
  `}

  svg {
    [stroke-width] {
      stroke: white;
    }
  }
`

const Dropdown = ({
  label,
  options,
  onChange,
  onUpdate,
  alwaysNative,
  neverNative,
  defaultValue,
  value,
  formVal,
  error,
  showComplete,
  disabled,
  link,
  theme,
  open,
  disableScroll
}) => {

  const [isNative, setNative] = useState(true);
  const [isOpen, setOpen] = useState(open);

  useEffect(() => {
    setOpen(open);
  }, [open])

  const handleChange = (value) => {
    if (link) {
      Router.push(value);
      return;
    }
    const item = options.find(item => item.value.toString() === value.toString());
    onChange(item)
    onUpdate(item) // for react hook forms
  }

  useEffect(() => {
    if (neverNative) {
      setNative(false);
    } else {
      const native = alwaysNative ? true : mobileCheck();
      setNative(native)
    }
  },[])


  const disableOnScroll = (e) => {

    if (document.body.classList.contains('overflow-hidden')) {
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  useEffect(() => {
    if (disableScroll) {
      window.addEventListener("wheel",  (e) => disableOnScroll(e), {passive: false});
      return window.removeEventListener("wheel", disableOnScroll);
    }
  }, [])

  const val = typeof(value) === 'object' ? get(value, 'value', undefined) : value
  
  const dropdownRef = useRef(null);

  return (
    <ThemeProvider theme={theme === 'lite' ? liteTheme : theme === 'v2' ? liteTheme : dropdownTheme}>
      <FormControl ref={dropdownRef} variant="outlined" fullWidth classes={{ root: showComplete && !error ? dropdownTheme.Complete : ''}}>
        {!isNative && !formVal &&
        <InputLabel 
          disableAnimation={true}
          focused={false}
          shrink={false}
          disabled={disabled}
          >{label}</InputLabel>
        }
        <Select
          onChange={(e) => {handleChange(e.target.value)}}
          native={isNative}
          value={formVal ? formVal.value : val || ''}
          defaultValue={defaultValue || undefined}
          error={error}
          disabled={disabled}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          IconComponent={() => (
            <ChevronIcon theme={theme}>
              <ReactSVG src={chevronDown} />
            </ChevronIcon>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center"
            },
            getContentAnchorEl: null,
            disableScrollLock: true,
          }}
        >
          {isNative && !defaultValue && label && <option value="">{label}</option> }
          {options.map(item => (
            isNative ? 
              <option key={item.id || item.value} value={item.value}>{renderHTML(item.text)}</option>
              :
              <MenuItem key={item.id || item.value} value={item.value}>{renderHTML(item.text)}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  )
}
  
Dropdown.propTypes = {
  label: propTypes.string,
  options: propTypes.array.isRequired,
  onChange: propTypes.func,
  onUpdate: propTypes.func,
  alwaysNative: propTypes.bool,
  formVal: propTypes.object,
  defaultValue: propTypes.string,
  value: propTypes.any,
  error: propTypes.bool,
  showComplete: propTypes.bool,
  disabled: propTypes.bool,
  link:  propTypes.bool,
  neverNative: propTypes.bool,
  theme: propTypes.oneOf(['regular', 'lite']),
  open: propTypes.bool,
  disableScroll: propTypes.bool
}
  
Dropdown.defaultProps = {
  label: 'Select',
  onChange: () => {},
  alwaysNative: false,
  formVal: undefined,
  defaultValue: undefined,
  value: undefined,
  error: false,
  showComplete: false,
  neverNative: false,
  onUpdate: () => {},
  disabled: false,
  link: false,
  theme: 'regular',
  open: false,
  disableScroll: true
}
  
export default Dropdown