import styled, { css } from "styled-components";
import IconButton from '@material-ui/core/IconButton';
import {theme} from '../../../themes/default-theme';

const Icon = styled(({ small, medium, large, white, grey, darkGrey, lightGrey, vividBlue, skyBlue, infoGrey, gallery, outline, play, ...props }) => {
  return <IconButton {...props} />
})`
  width: 34px;
  height: 34px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.white};
  &:hover {
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.middleGrey};
  }
  & svg {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  ${theme.breakpoints.down('sm')} {
    width: 22px;
    height: 22px;
    & svg {
      max-height: 12px;
    }
  }

  ${props =>
    props.small &&
      css`
        width: 22px;
        height: 22px;
        & svg {
          max-height: 12px;
        }
  `}
  ${props =>
    props.medium &&
      css`
        width: 56px;
        height: 56px;
        & svg {
          max-height: 19px;
        }
  `}
  ${props =>
    props.large &&
      css`
        width: 100px;
        height: 100px;
        ${theme.breakpoints.down('sm')} {
          width: 50px !important;
          height: 50px !important;
          & svg {
            max-height: 24px;
          }
        }
  `}

  ${props =>
    props.white &&
      css`
        background: ${theme.palette.white};
        border: 1px solid ${theme.palette.white};
        &:hover {
          background: ${theme.palette.white};
          border: 1px solid ${theme.palette.middleGrey};
        }
  `}
  ${props =>
    props.lightGrey &&
      css`
      background: ${theme.palette.lightGrey};
      border: 1px solid ${theme.palette.lightGrey};
      opacity: 1;
      &:hover {
        background: ${theme.palette.middleGrey};
        border: 1px solid ${theme.palette.lightGrey};
        opacity: 1;
      }
      svg {
        [stroke] {
          stroke: ${theme.palette.gunmentalGrey}
        }
      }
  `}
  ${props =>
    props.darkGrey &&
      css`
      background: ${theme.palette.darkGrey2};
      border: 1px solid ${theme.palette.darkGrey2};
      opacity: 0.5;
      &:hover {
        background: ${theme.palette.darkGrey2};
        border: 1px solid ${theme.palette.darkGrey2};
        opacity: 1;
      }
      svg {
        [stroke] {
          stroke: ${theme.palette.white}
        }
      }
  `}
  ${props =>
    props.grey &&
      css`
        background: ${theme.palette.darkGrey};
        border: 1px solid ${theme.palette.white};
        &:hover {
          color: ${theme.palette.skyBlue};
          background: ${theme.palette.darkGrey};
          border: 1px solid ${theme.palette.middleGrey};
          & > * {
            filter: brightness(100%);
          }
        }
        & > * {
          filter: brightness(500%);
        }
  `}
  ${props =>
    props.vividBlue &&
      css`
        background: ${theme.palette.vividBlue};
        border: none;
        &:hover {
          background: ${theme.palette.vividBlueHover};
          border: none;
        }
        & > * {
          filter: brightness(500%);
        }
  `}
  ${props =>
    props.skyBlue &&
      css`
        color: ${theme.palette.white};
        background: ${theme.palette.skyBlue};
        border: none;
        &:hover {
          background: ${theme.palette.skyBlueHover};
          border: none;
        }
        & > * {
          filter: brightness(500%);
        }
        .MuiTouchRipple-child {
          background-color: ${theme.palette.charcoal};
        }
  `}
  ${props =>
    props.infoGrey &&
      css`
        background: ${theme.palette.middleGrey};
        border: 1px solid ${theme.palette.middleGrey};
        opacity: 50%;
        &:hover {
          background: ${theme.palette.gunmentalGrey};
          border: 1px solid ${theme.palette.middleGrey};
          opacity: 100%;
          & > * {
            filter: brightness(500%);
          }
        }
        & > * {
          filter: brightness(500%);
        }
  `}
  ${props =>
    props.gallery &&
      css`
        border-top-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
  `}
  ${props =>
    props.outline &&
      css`
        border: 1px solid ${theme.palette.middleGrey};
        &:hover {
          border: 1px solid ${theme.palette.middleGrey};
        }
  `}
  ${props =>
    props.play &&
      css`
        background ${theme.palette.greyOpacity};
        border: 2px solid ${theme.palette.white};
        .MuiIconButton-label {
          transform: translate(4%, -40%);
        }
        &:hover {
          background ${theme.palette.greyOpacity};
          border: 2px solid ${theme.palette.white};
          svg {
            [fill] {
              fill: ${theme.palette.vividBlue}
            }
          }
        }
  `}
`;

export default Icon;