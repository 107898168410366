import styled, { css } from "styled-components";
import ButtonBase from '@material-ui/core/ButtonBase';

import {theme} from '../../../themes/default-theme';
import lightenDarkenColor from '../../../util/lightenDarkenColor';

const Cta =  styled(({ primary, secondary, yellow, outline, outlineWhite, uppercase, inline, small, ...props }) => {
  return <ButtonBase {...props} />
})`
  background: #0170D4;
  border-radius: 24px;
  border: 0;
  color: white;
  font-size: 13px;
  width: 100%;
  padding: 9px 30px;
  text-transform: none;
  text-decoration: none;
  font-weight: 700;
  ${theme.breakpoints.up('md')} {
    padding: 13px 30px;
    font-size: 15px;
  }
  ${props => props.disabled && `opacity: 0.5;`}

  &:hover {
    background: #0C5393;
  }


  ${props =>
    props.primary &&
      css`
        background: ${theme.palette.vividBlue};
        &:hover {
          background: #0C5393;
        }
  `}
  ${props =>
    props.secondary &&
      css`
        background: ${theme.palette.skyBlue};
        &:hover {
          background: #0D7ABE;
        }
  `}
  ${props =>
    props.yellow &&
      css`
        background: ${theme.palette.yellow};
        color:  ${theme.palette.charcoal};
        &:hover {
          background: #E8BF16;
        }
  `}
  ${props =>
    props.uppercase ?
      css`
        text-transform: uppercase;
  ` : css`
    text-transform: uppercase;
  `}
  ${props =>
    props.outline &&
      css`
        background: none;
        ${props.outlineWhite ? `
          color: white;
          border: 2px solid white;
        `: `
          color: ${props.yellow ? theme.palette.yellow : props.secondary ? theme.palette.skyBlue : theme.palette.vividBlue};
          border: 2px solid ${props.yellow ? theme.palette.yellow : props.secondary ? theme.palette.skyBlue : theme.palette.vividBlue};
        `}
        padding: 8px 20px;
        &:hover {
          background: none;
          filter: brightness(80%);
        }
  `}
  ${props =>
    props.inline &&
      css`
        display: inline-block;
        width: auto;
  `}
  ${props =>
    props.bg &&
      css`
        background: ${props.bg};
        &:hover {
          background: ${lightenDarkenColor(props.bg, -20)};
        }
      `
  }
  ${props =>
    props.color &&
      css`
        border-color: ${props.color};
        color: ${props.color};
      `
  }
`;

export default Cta;