export default () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];
  return toMatch.some((toMatchItem) => {
    if (typeof window === "undefined") {
      return false;
    } else {
      return window.navigator && navigator.userAgent.match(toMatchItem);
    }
  });
}